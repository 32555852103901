import React, { useEffect } from 'react'
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import { getImage } from "gatsby-plugin-image";
import SEO from "../components/seo"

import AOS from 'aos';
import 'aos/dist/aos.css'

import FullWidthImage from "../components/FullWidthImage";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import ContentLayout from "../components/ContentLayout";



// eslint-disable-next-line
export const IndexPageTemplate = ({   
  image,
  title,
  heading,
  subheading, 
  topContentText,
  topContentTagline,
  topContentImage,
  middleContentText,
  middleContentTagline,
  middleContentImage,
  bottomContentText,
  bottomContentTagline,
  bottomContentImage,
}) => {

  useEffect(() => {
    AOS.init();
  }, [])

  const heroImage = getImage(image) || image;
  // console.log(heroImage)
  // console.log(heroImage)
  return (
    <div>
      <FullWidthImage img={heroImage} title={title} subheading={subheading} />
      <div>   
        <ContentLayout Image={topContentImage} Tagline={topContentTagline} Text={topContentText} imagePosition="right" />   
      </div>
      <div>   
        <ContentLayout Image={middleContentImage} Tagline={middleContentTagline} Text={middleContentText}  />   
      </div>
      <div>   
        <ContentLayout Image={bottomContentImage} Tagline={bottomContentTagline} Text={bottomContentText} imagePosition="right" />   
      </div>
    </div>
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string.isRequired,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  topContentImage:  PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  topContentText:  PropTypes.string,
  topContentTagline:  PropTypes.string,
  middleContentImage:  PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  middleContentText:  PropTypes.string,
  middleContentTagline:  PropTypes.string,
  bottomContentImage:  PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  bottomContentText:  PropTypes.string,
  bottomContentTagline:  PropTypes.string,
};

const IndexPage = ({ data }) => {
  const { markdownRemark: post } = data;
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <SEO title={frontmatter.meta.title} description={frontmatter.meta.description} />
      <IndexPageTemplate
        title={post.frontmatter.title}
        image={frontmatter.image}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        topContentImage={frontmatter.topContentImage}
        topContentText={frontmatter.topContentText}
        topContentTagline={frontmatter.topContentTagline}
        middleContentImage={frontmatter.middleContentImage}
        middleContentText={frontmatter.middleContentText}
        middleContentTagline={frontmatter.middleContentTagline}
        bottomContentImage={frontmatter.bottomContentImage}
        bottomContentText={frontmatter.bottomContentText}
        bottomContentTagline={frontmatter.bottomContentTagline}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
};



export default IndexPage;

export const indexPageQuery = graphql`
  query IndexPageTemplate($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        heading
        subheading
        topContentText
        topContentTagline
        topContentImage {
          alt
          image {
            childImageSharp {
              gatsbyImageData(width: 526, quality: 92, layout: CONSTRAINED)
            }
          }
        }
        middleContentText
        middleContentTagline
        middleContentImage {
          alt
          image {
            childImageSharp {
              gatsbyImageData(width: 526, quality: 92, layout: CONSTRAINED)
            }
          }
        }
        bottomContentText
        bottomContentTagline
        bottomContentImage {
          alt
          image {
            childImageSharp {
              gatsbyImageData(width: 526, quality: 92, layout: CONSTRAINED)
            }
          }
        }
        meta {
          title
          description
        }
      }
    }
  }
`;
